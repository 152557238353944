// This is ok to be public
const firebaseConfig = {
  apiKey: 'AIzaSyDj0aR9Qzm2d3slm6QM4pj6pfnbsz-ZfD0',
  authDomain: 'e-shop-db-640cb.firebaseapp.com',
  projectId: 'e-shop-db-640cb',
  storageBucket: 'e-shop-db-640cb.appspot.com',
  messagingSenderId: '135503183111',
  appId: '1:135503183111:web:b9caa85701cba1e8ee19a2',
};
export default firebaseConfig;
